import React from "react"
import Layout from "../components/layout"
import { RedButton } from "../components/button-red"

const SuccessPage = () => {
    return (
        <Layout>
            <div className="bg-newlndgray py-16 px-5 lg:px-10">
                <div className="container mx-auto text-center">
                    <div className="max-w-lg mx-auto">
                        <h3 className="font-bold text-2xl lg:text-3xl pb-3">Submission Successful!</h3>
                        <p className="pb-5">
                            Our team has received your message and will get back to you within 24 hours. Thank you for your interest in our services!
                        </p>
                        <RedButton buttonPage="/services" buttonText="Browse Services" title="View Services" alt="View Services - L&D Solutions" />
                        <RedButton buttonPage="/" buttonText="Return Home" title="Home Page" alt="Home Page - L&D Solutions" />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default SuccessPage