import React, { Component } from "react"
import { Link } from "gatsby"

// TO USE: takes in two props: 1) buttonPage - page name to link to and 2) buttonText - text inside button
export class RedButton extends Component {
    render() {
        return (
            <div>
                <Link to={this.props.buttonPage} title={this.props.title} alt={this.props.alt}>
                    <button className={`m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out ${this.props.invert ? 'text-lndred hover:bg-lndred hover:text-white' : 'bg-lndred text-white hover:shadow-lg hover:shadow-lndred'}`}>
                        <span>{this.props.buttonText}</span>
                    </button>
                </Link>
            </div>
        )
    }
}